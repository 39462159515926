/* Import Lato font */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body, .roi-calculator-container {
    font-family: 'Lato', sans-serif;
}

/* Main container */
.roi-calculator {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    padding: 10px;
    box-sizing: border-box;
}

/* Gradient background and styling for the calculator container */
.roi-calculator-container {
    padding: 30px;
    background: linear-gradient(135deg, #03555c, #1e4f68, #65304c);
    color: #fff;
    max-width: 700px;
    width: 100%;
    border-radius: 12px;
}

/* Title styling */
.title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-align: left; /* Default alignment for larger screens */
}

/* Responsive title adjustment */
@media (max-width: 600px) {
    .title {
        text-align: center; /* Center title on small screens */
    }
}

/* Input group styling */
.input-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
}

/* Individual input item styling */
.input-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap; /* Allows wrapping if space is tight */
}

/* Label styling */
label {
    font-size: 0.9rem;
    flex-basis: 50%; /* Takes up 50% of the space */
    white-space: nowrap; /* Prevents wrapping unless needed */
    text-align: left; /* Default left alignment */
}

/* Input wrapper */
.input-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    max-width: 100%;
}

/* Input field styling */
input[type="number"],
input[type="text"] {
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 180px;
    flex: 1;
}

/* Read-only input styling */
input[readonly] {
    background-color: #e9ecef;
    cursor: not-allowed;
}

/* Span inside input wrapper */
.input-wrapper span {
    margin-left: 5px;
    white-space: nowrap;
}

/* Separator line */
.separator {
    height: 1px;
    background-color: #ffffff;
    margin: 20px 0;
}

/* Button container styling */
.button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

/* Toggle button styling */
.toggle-button {
    padding: 10px 15px;
    background-color: #1e3e50;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
}

/* Assumptions container */
.assumptions-container {
    margin-top: 15px;
    background-color: #1e3e50;
    padding: 15px;
    border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .input-group {
        flex-direction: column;
    }

    .input-item {
        flex-direction: column; /* Stack label and input vertically */
        align-items: center; /* Center align on small screens */
        text-align: center; /* Center text on small screens */
        gap: 10px;
    }

    label {
        margin-bottom: 5px;
        width: 100%; /* Full width for better wrapping */
        text-align: center; /* Center text on small screens */
        white-space: normal; /* Allow wrapping */
    }

    .input-wrapper {
        width: 100%;
        max-width: 100%;
        justify-content: center; /* Center input on small screens */
    }

    .button-container {
        flex-direction: column;
        align-items: center;
    }
}
